<template>
    <div class="text-center">
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <vs-button class="btn-drop" type="flat" icon="more_horiz"></vs-button>
            <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 13rem">
                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="assignDevice">
                        <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">cấp phát vật dụng</span>
                    </li>
                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="confirmDeleteRecord">
                        <feather-icon icon="UserXIcon" svgClasses="w-4 h-4"/>
                        <span class="ml-2">Thu hồi vật dụng</span>
                    </li>
                </ul>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        assignDevice() {
            // if (this.params.data.employeeId) {
            //   return this.$vs.dialog({
            //     color: 'danger',
            //     title: `Lỗi`,
            //     text: `Thiết bị đã cấp phát. Xin thu hồi trước khi cấp phát lại`,
            //     acceptText: "Đồng ý"
            //   })
            // }
            this.$router.push(`/user/assign-equipment?branch_id=${this.params.data.organizationBranchId}&device_id=${this.params.data.deviceId}&id=${this.params.data.id || ""}`).catch(() => {
            })
        },
        editRecord() {
            this.$router.push(`/user/edit-device?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.id}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận thu hồi`,
                text: `Bạn có chắc là muốn thu hồi "${this.params.data.displayDevice}" của "${this.params.data.fullName}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xác nhận",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post('/device/revoke/' + this.params.data.id).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_ASSIGNED_DEVICE', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Thu hồi thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
